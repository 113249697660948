import { gql } from '@apollo/client';

export const eventsFragment = gql`
  fragment EventsFields on ProductsResponse {
    rows {
      uuid
      startedDate
      name
      purchasedCount
      visitedCount
    }
    count
  }
`;
export const events = gql`
  ${eventsFragment}
  query events(
    $filters: ProductListFilterInput
    $organizerId: Int!
    $status: [StatusEnum!]
  ) {
    productsList(
      filters: $filters
      params: { organizerId: $organizerId }
      status: $status
    ) {
      ...EventsFields
    }
  }
`;

export const event = gql`
  query event($uuid: UUID!) {
    product(uuid: $uuid) {
      uuid
      name
      description
      startedDate
      purchasedCount
      visitedCount
    }
  }
`;
