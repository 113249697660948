import { OrderStatusEnum } from '@/apolloGenerated';

export const getReadableOrderStatus = (status: OrderStatusEnum): string => {
  switch (status) {
    case OrderStatusEnum.NotPaid: {
      return 'Не оплачен';
    }
    case OrderStatusEnum.Paid:
    case OrderStatusEnum.CompletedAndNotVisited: {
      return 'Не активирован';
    }
    case OrderStatusEnum.CompletedAndVisited: {
      return 'Активирован';
    }
    case OrderStatusEnum.Refunded: {
      return 'Возвращён';
    }
    case OrderStatusEnum.TransferredToProduct: {
      return 'Перенесён';
    }
    case OrderStatusEnum.TransferredToUser: {
      return 'Передан';
    }
    case OrderStatusEnum.Deactivated: {
      return 'Деактивирован';
    }
  }
};

export const canActionsOrder = (status: OrderStatusEnum) =>
  [
    OrderStatusEnum.Paid,
    OrderStatusEnum.CompletedAndNotVisited,
    OrderStatusEnum.CompletedAndVisited,
  ].includes(status);
export const isActivatedOrder = (status: OrderStatusEnum) =>
  status === OrderStatusEnum.CompletedAndVisited;
