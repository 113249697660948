import { FC, PropsWithChildren } from 'react';
import { Redirect } from 'react-justanother-router';

import { useMeQuery } from '@/apolloGenerated';
import { RouterName } from '@app/router';

import styles from './styles.module.scss';

export const AuthLayout: FC<PropsWithChildren> = ({ children }) => {
  const { data } = useMeQuery();

  if (data?.me) {
    return <Redirect to={RouterName.QrScan} />;
  }

  return (
    <div className={styles.auth}>
      <div className={styles.auth__container}>
        <div className={styles.auth__content}>{children}</div>
      </div>
    </div>
  );
};
