import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends Record<string, unknown>> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<T extends Record<string, unknown>, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;

/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: number; output: number };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the
   * `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO
   * 8601 standard for representation of dates and times using the Gregorian calendar.
   */
  DateTime: { input: Date; output: Date };
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the
   * `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO
   * 8601 standard for representation of dates and times using the Gregorian
   * calendar.This scalar is serialized to a string in ISO 8601 format and parsed
   * from a string in ISO 8601 format.
   */
  DateTimeISO: { input: any; output: any };
  /**
   * A field whose value conforms to the standard internet email address format as
   * specified in HTML Spec:
   * https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address.
   */
  EmailAddress: { input: any; output: any };
  /**
   * A string representing a duration conforming to the ISO8601 standard,
   * such as: P1W1DT13H23M34S
   * P is the duration designator (for period) placed at the start of the duration representation.
   * Y is the year designator that follows the value for the number of years.
   * M is the month designator that follows the value for the number of months.
   * W is the week designator that follows the value for the number of weeks.
   * D is the day designator that follows the value for the number of days.
   * T is the time designator that precedes the time components of the representation.
   * H is the hour designator that follows the value for the number of hours.
   * M is the minute designator that follows the value for the number of minutes.
   * S is the second designator that follows the value for the number of seconds.
   *
   * Note the time designator, T, that precedes the time value.
   *
   * Matches moment.js, Luxon and DateFns implementations
   * ,/. is valid for decimal places and +/- is a valid prefix
   */
  ISO8601Duration: { input: any; output: any };
  /**
   * A field whose value conforms to the standard E.164 format as specified in:
   * https://en.wikipedia.org/wiki/E.164. Basically this is +17895551234.
   */
  PhoneNumber: { input: any; output: any };
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: { input: any; output: any };
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any };
  /** Represents NULL values */
  Void: { input: any; output: any };
}

export interface AmountStatsType {
  __typename?: 'AmountStatsType';
  commission: Scalars['Float']['output'];
  profit: Scalars['Float']['output'];
  referral: Scalars['Float']['output'];
  turnover: Scalars['Float']['output'];
}

export interface BaseBotType {
  __typename?: 'BaseBotType';
  id: Scalars['Float']['output'];
  username: Scalars['String']['output'];
}

export interface BaseGraphModel {
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  updatedAt: Scalars['DateTimeISO']['output'];
}

export interface BaseTariffInput {
  description?: InputMaybe<Scalars['String']['input']>;
  featureFlags: TariffFeatureFlagsInput;
  name: Scalars['String']['input'];
  ordersCount?: InputMaybe<Scalars['Float']['input']>;
  price: Scalars['Float']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  type?: TariffTypeEnum;
  visible: Scalars['Boolean']['input'];
}

export interface BaseTariffListFilterInput {
  order?: InputMaybe<BaseTariffSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<BaseTariffSearchType>;
}

export enum BaseTariffSearchKeysEnum {
  Description = 'description',
  Name = 'name',
  Type = 'type',
}

export interface BaseTariffSearchType {
  search: Scalars['String']['input'];
  searchBy: BaseTariffSearchKeysEnum;
}

export enum BaseTariffSortKeysEnum {
  CreatedAt = 'createdAt',
  Name = 'name',
  OrdersCount = 'ordersCount',
  Price = 'price',
  Query = 'query',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  Visible = 'visible',
}

export interface BaseTariffSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: BaseTariffSortKeysEnum;
}

export type BaseTariffType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'BaseTariffType';
    countSubTariffs: Scalars['Int']['output'];
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    description?: Maybe<Scalars['String']['output']>;
    featureFlags: TariffFeatureFlagsType;
    name: Scalars['String']['output'];
    ordersCount?: Maybe<Scalars['Float']['output']>;
    price: Scalars['Float']['output'];
    query?: Maybe<Scalars['String']['output']>;
    status: StatusEnum;
    type: TariffTypeEnum;
    updatedAt: Scalars['DateTimeISO']['output'];
    uuid: Scalars['UUID']['output'];
    visible: Scalars['Boolean']['output'];
  };

export interface BaseTariffsResponse {
  __typename?: 'BaseTariffsResponse';
  count: Scalars['Int']['output'];
  rows: BaseTariffType[];
}

export enum BaseTextsKeysEnum {
  OrganizerOrderInstructionsRefundText = 'OrganizerOrderInstructionsRefundText',
  OrganizerOrderInstructionsTransferToProductText = 'OrganizerOrderInstructionsTransferToProductText',
  OrganizerOrderInstructionsTransferToUserText = 'OrganizerOrderInstructionsTransferToUserText',
  OrganizerSocialText = 'OrganizerSocialText',
  ProductInviteOrderSuccessText = 'ProductInviteOrderSuccessText',
  ProductInviteOrderText = 'ProductInviteOrderText',
  ProductSalesStartedText = 'ProductSalesStartedText',
  ProductSuccessBuyText = 'ProductSuccessBuyText',
  ProductTariffsEmptyText = 'ProductTariffsEmptyText',
  ProductTariffsText = 'ProductTariffsText',
  ProductTariffsUnavailableText = 'ProductTariffsUnavailableText',
  ProductTransferOrderToUserSuccessText = 'ProductTransferOrderToUserSuccessText',
}

export interface BaseTextsType {
  __typename?: 'BaseTextsType';
  key: BaseTextsKeysEnum;
  text: Scalars['String']['output'];
}

export interface BotInfoType {
  __typename?: 'BotInfoType';
  id: Scalars['Float']['output'];
  token: Scalars['String']['output'];
  username: Scalars['String']['output'];
}

export interface BotInput {
  name: Scalars['String']['input'];
  token: Scalars['String']['input'];
}

export interface BotListFilterInput {
  order?: InputMaybe<BotSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<BotSearchType>;
}

export interface BotListParamsInput {
  organizerId?: InputMaybe<Scalars['Float']['input']>;
}

export enum BotSearchKeys {
  Id = 'id',
  Name = 'name',
  Token = 'token',
  Username = 'username',
}

export interface BotSearchType {
  search: Scalars['String']['input'];
  searchBy: BotSearchKeys;
}

export enum BotSortKeys {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  Token = 'token',
  UpdatedAt = 'updatedAt',
  Username = 'username',
}

export interface BotSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: BotSortKeys;
}

export type BotType = BaseGraphModel & {
  __typename?: 'BotType';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  organizers: OrganizerType[];
  token: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  username: Scalars['String']['output'];
  usersCount: Scalars['Int']['output'];
};

export interface BotsResponse {
  __typename?: 'BotsResponse';
  count: Scalars['Int']['output'];
  rows: BotType[];
}

export interface CreateAdminUserInput {
  organizerId: Scalars['Int']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
}

export interface CreateInvoiceInput {
  keyReferralOrganizer?: InputMaybe<Scalars['String']['input']>;
  uuidPairUser?: InputMaybe<Scalars['UUID']['input']>;
  uuidTariff: Scalars['UUID']['input'];
}

export interface CreateInvoiceType {
  __typename?: 'CreateInvoiceType';
  order: OrderType;
  payment?: Maybe<PaymentType>;
}

export interface ErrorType {
  __typename?: 'ErrorType';
  errors: Errors;
}

export enum Errors {
  AcquiringTurnOff = 'AcquiringTurnOff',
  AlreadyExecuted = 'AlreadyExecuted',
  AlreadyHasInvite = 'AlreadyHasInvite',
  AlreadyPaid = 'AlreadyPaid',
  BotNotFound = 'BotNotFound',
  CanActivateOnlyDraftItem = 'CanActivateOnlyDraftItem',
  CanArchiveOnlyActiveItem = 'CanArchiveOnlyActiveItem',
  CanNotAddArchiveTariffForTransfer = 'CanNotAddArchiveTariffForTransfer',
  CanNotAddLimitedTariffForTransfer = 'CanNotAddLimitedTariffForTransfer',
  CanNotAddNotAnyGenderTariffForTransfer = 'CanNotAddNotAnyGenderTariffForTransfer',
  CanNotChangeUserInfo = 'CanNotChangeUserInfo',
  CanNotClearCurrentUser = 'CanNotClearCurrentUser',
  CanNotCreateOrderWithDifferentGender = 'CanNotCreateOrderWithDifferentGender',
  CanNotEditAdminUser = 'CanNotEditAdminUser',
  CanNotRemoveActiveProduct = 'CanNotRemoveActiveProduct',
  CanNotRemoveProductWithOrders = 'CanNotRemoveProductWithOrders',
  CanNotTransferOrderUserWithDifferentGender = 'CanNotTransferOrderUserWithDifferentGender',
  CouldNotRefreshToken = 'CouldNotRefreshToken',
  EmailAlreadyConfirmed = 'EmailAlreadyConfirmed',
  EventNotBelongOrganizer = 'EventNotBelongOrganizer',
  FailedConfirmEmail = 'FailedConfirmEmail',
  FailedGetPayment = 'FailedGetPayment',
  FailedInitPayment = 'FailedInitPayment',
  FailedRemovePaymentAccount = 'FailedRemovePaymentAccount',
  FailedSendMail = 'FailedSendMail',
  FailedUpdatePayment = 'FailedUpdatePayment',
  FailedUploadFile = 'FailedUploadFile',
  FeatureDisabled = 'FeatureDisabled',
  IncorrectBotToken = 'IncorrectBotToken',
  IncorrectOrderStatusForAction = 'IncorrectOrderStatusForAction',
  InsufficientFundsOnBalance = 'InsufficientFundsOnBalance',
  InsufficientFundsOnUserBalance = 'InsufficientFundsOnUserBalance',
  InvalidAccessToken = 'InvalidAccessToken',
  InvalidAuthData = 'InvalidAuthData',
  InvalidCode = 'InvalidCode',
  InvalidFileType = 'InvalidFileType',
  MaxBotsOrganizerHasBeenAchieved = 'MaxBotsOrganizerHasBeenAchieved',
  MaxTransfersOrderReached = 'MaxTransfersOrderReached',
  NotFound = 'NotFound',
  NotFoundAccessToken = 'NotFoundAccessToken',
  NotPassedFullSupplierInfo = 'NotPassedFullSupplierInfo',
  NotPassedOrderForDepositUserBalance = 'NotPassedOrderForDepositUserBalance',
  NotSendCode = 'NotSendCode',
  OrderIsNotPaid = 'OrderIsNotPaid',
  OrderNotFound = 'OrderNotFound',
  OrderWithEmptyAmount = 'OrderWithEmptyAmount',
  OrganizerAlreadyHasBot = 'OrganizerAlreadyHasBot',
  OrganizerHasNotBot = 'OrganizerHasNotBot',
  OrganizerNotActive = 'OrganizerNotActive',
  OrganizerNotFound = 'OrganizerNotFound',
  PairUserNotFound = 'PairUserNotFound',
  PairUserNotPassed = 'PairUserNotPassed',
  PairUserWithSameGender = 'PairUserWithSameGender',
  PaymentAccountNotFound = 'PaymentAccountNotFound',
  PaymentNotActive = 'PaymentNotActive',
  PaymentNotFound = 'PaymentNotFound',
  PermissionDenied = 'PermissionDenied',
  ProductAlreadyHaveThisStatus = 'ProductAlreadyHaveThisStatus',
  ProductIsArchived = 'ProductIsArchived',
  ProductIsNotActive = 'ProductIsNotActive',
  ProductNotFound = 'ProductNotFound',
  RepeatOrderTurnOff = 'RepeatOrderTurnOff',
  SalesNotStarted = 'SalesNotStarted',
  TariffForTransferNotFound = 'TariffForTransferNotFound',
  TariffIsUnavailable = 'TariffIsUnavailable',
  TariffNotActive = 'TariffNotActive',
  TariffNotFound = 'TariffNotFound',
  UnknownError = 'UnknownError',
  UserAlreadyBlocked = 'UserAlreadyBlocked',
  UserAlreadyHaveAccessTariff = 'UserAlreadyHaveAccessTariff',
  UserAlreadyHaveNotAccessTariff = 'UserAlreadyHaveNotAccessTariff',
  UserAlreadyHaveOrder = 'UserAlreadyHaveOrder',
  UserAlreadyInBot = 'UserAlreadyInBot',
  UserAlreadyMemberInGroup = 'UserAlreadyMemberInGroup',
  UserAlreadySpecial = 'UserAlreadySpecial',
  UserBalanceNotFound = 'UserBalanceNotFound',
  UserGroupNotFound = 'UserGroupNotFound',
  UserNotBlocked = 'UserNotBlocked',
  UserNotFound = 'UserNotFound',
  UserNotFoundInSpecial = 'UserNotFoundInSpecial',
  UserNotMemberInGroup = 'UserNotMemberInGroup',
  UserNotRegistered = 'UserNotRegistered',
  UserWithThisEmailAlreadyExist = 'UserWithThisEmailAlreadyExist',
  UserWithThisTokenNotFound = 'UserWithThisTokenNotFound',
}

export interface ExportOrdersCsvType {
  __typename?: 'ExportOrdersCSVType';
  url: Scalars['String']['output'];
}

export interface FileType {
  __typename?: 'FileType';
  url: Scalars['String']['output'];
}

export interface FilterUsersInput {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isRegistered?: InputMaybe<Scalars['Boolean']['input']>;
}

export enum GenderEnum {
  Female = 'Female',
  Male = 'Male',
}

export interface GiveOrderResultType {
  __typename?: 'GiveOrderResultType';
  error?: Maybe<Errors>;
  id?: Maybe<Scalars['Float']['output']>;
  status: Scalars['Boolean']['output'];
  uuidUser: Scalars['UUID']['output'];
}

export interface JwtType {
  __typename?: 'JwtType';
  access_token: Scalars['String']['output'];
  refresh_token: Scalars['String']['output'];
}

export enum LanguageEnum {
  English = 'English',
  Russian = 'Russian',
}

export interface LoginInput {
  login: Scalars['String']['input'];
  password: Scalars['String']['input'];
}

export interface LoginTgInput {
  password: Scalars['String']['input'];
  tgId: Scalars['Float']['input'];
}

export interface Mutation {
  __typename?: 'Mutation';
  activateBot: Scalars['Void']['output'];
  /** Method for activating an order by organizer on event */
  activateOrder: Scalars['Void']['output'];
  activateProduct: ProductType;
  addBotToOrganizer: Scalars['Void']['output'];
  addPaymentAccount: PaymentAccountType;
  addUserBalance: Scalars['Boolean']['output'];
  addUserOrganizer: Scalars['Void']['output'];
  addUserToBlacklist: Scalars['Void']['output'];
  addUserToBot: Scalars['Void']['output'];
  addUserToGroup: Scalars['Void']['output'];
  archiveProduct: ProductType;
  botInfoByToken: BotInfoType;
  cancelInvite: Scalars['Void']['output'];
  cancelInvoice: Scalars['Void']['output'];
  cancelWithdrawal: Scalars['Void']['output'];
  changeLanguage: Scalars['Void']['output'];
  clearUserByTg: Scalars['Void']['output'];
  confirmEmail: Scalars['Void']['output'];
  crateUserGroup: UserGroupType;
  createAdminUser: UserType;
  createBaseTariff: BaseTariffType;
  createBot: BotType;
  createInviteOrders: OrderInviteResultType[];
  createInvoice: CreateInvoiceType;
  createOrganizer: OrganizerType;
  createProduct: ProductType;
  createReferralOrganizer: ReferralOrganizerType;
  createTariff: SubTariffType;
  /** Method for deactivating an order by organizer on event */
  deactivateOrder: Scalars['Void']['output'];
  deactivateUserOrganizer?: Maybe<UserType>;
  disableBot: Scalars['Void']['output'];
  /** Method for disabling an order and canceling payment with a refund from the teacher’s balance */
  disableOrder: OrderType;
  duplicateBaseTariff: BaseTariffType;
  duplicateProduct: ProductType;
  exportOrdersCSV: ExportOrdersCsvType;
  giveOrders: GiveOrderResultType[];
  login: JwtType;
  loginTg: JwtType;
  logout: Scalars['Void']['output'];
  payBonusInvoice: PaymentType;
  refresh: JwtType;
  refundOrder: OrderType;
  removeBaseTariff: Scalars['Void']['output'];
  removeBot: Scalars['Void']['output'];
  removeBotFromOrganizer: Scalars['Void']['output'];
  removePaymentAccount: Scalars['Void']['output'];
  removeProduct: Scalars['Void']['output'];
  removeReferralOrganizer: Scalars['Void']['output'];
  removeTariff: Scalars['Void']['output'];
  removeUserFromBlacklist: Scalars['Void']['output'];
  removeUserFromGroup: Scalars['Void']['output'];
  removeUserGroup: Scalars['Void']['output'];
  renameReferralOrganizer: ReferralOrganizerType;
  requestWithdrawal: Scalars['Void']['output'];
  saveUserInfo: UserType;
  signUp: UserType;
  submitInvite: OrderType;
  /** A way to transfer your order to another product. The order must not be completed */
  transferOrderToProduct: OrderType;
  /** Method for transferring your order to another user. The order must not be completed */
  transferOrderToUser: OrderType;
  updateBaseTariff: BaseTariffType;
  updateOrganizer: OrganizerType;
  updatePaymentAccount?: Maybe<PaymentAccountType>;
  updateProduct: ProductType;
  updateProductInviteTexts: ProductType;
  updateProductTariffs: Scalars['Void']['output'];
  updateTariff: SubTariffType;
  updateUser: UserType;
  updateUserGroupName: UserGroupType;
  updateUserGroupsBaseTariff: Scalars['Void']['output'];
  updateUsersInGroup: Scalars['Void']['output'];
  uploadFile: FileType;
  uploadImages: FileType[];
  verifyEmail: UserType;
  verifyEmailLater: UserType;
}

export interface MutationActivateBotArgs {
  id: Scalars['Float']['input'];
}

export interface MutationActivateOrderArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationActivateProductArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationAddBotToOrganizerArgs {
  idBot: Scalars['Float']['input'];
  idOrganizer: Scalars['Float']['input'];
}

export interface MutationAddPaymentAccountArgs {
  idOrganizer: Scalars['Int']['input'];
  input: PaymentAccountInput;
}

export interface MutationAddUserBalanceArgs {
  amount: Scalars['Float']['input'];
  organizerId: Scalars['Int']['input'];
  uuidUser: Scalars['UUID']['input'];
}

export interface MutationAddUserOrganizerArgs {
  idOrganizer: Scalars['Float']['input'];
}

export interface MutationAddUserToBlacklistArgs {
  organizerId: Scalars['Float']['input'];
  uuid: Scalars['UUID']['input'];
}

export interface MutationAddUserToGroupArgs {
  uuidGroup: Scalars['UUID']['input'];
  uuidUser: Scalars['UUID']['input'];
}

export interface MutationArchiveProductArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationBotInfoByTokenArgs {
  token: Scalars['String']['input'];
}

export interface MutationCancelInviteArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationCancelInvoiceArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationCancelWithdrawalArgs {
  idOrganizer: Scalars['Int']['input'];
  uuid: Scalars['UUID']['input'];
}

export interface MutationChangeLanguageArgs {
  language: LanguageEnum;
}

export interface MutationClearUserByTgArgs {
  tgId: Scalars['Float']['input'];
}

export interface MutationConfirmEmailArgs {
  email: Scalars['EmailAddress']['input'];
}

export interface MutationCrateUserGroupArgs {
  idOrganizer: Scalars['Int']['input'];
  name: Scalars['String']['input'];
}

export interface MutationCreateAdminUserArgs {
  input: CreateAdminUserInput;
}

export interface MutationCreateBaseTariffArgs {
  idOrganizer: Scalars['Int']['input'];
  input: BaseTariffInput;
}

export interface MutationCreateBotArgs {
  input: BotInput;
}

export interface MutationCreateInviteOrdersArgs {
  params?: InputMaybe<OrdersActionNotifyParams>;
  productUuid: Scalars['UUID']['input'];
  tariffUuid: Scalars['UUID']['input'];
  usersUuids: Scalars['UUID']['input'][];
}

export interface MutationCreateInvoiceArgs {
  input: CreateInvoiceInput;
}

export interface MutationCreateOrganizerArgs {
  input: OrganizerInput;
}

export interface MutationCreateProductArgs {
  input: ProductInput;
  organizerId: Scalars['Float']['input'];
  publish?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface MutationCreateReferralOrganizerArgs {
  input: ReferralOrganizerInput;
}

export interface MutationCreateTariffArgs {
  input: SubTariffInput;
  uuidParentTariff: Scalars['UUID']['input'];
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
}

export interface MutationDeactivateOrderArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationDeactivateUserOrganizerArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationDisableBotArgs {
  id: Scalars['Float']['input'];
}

export interface MutationDisableOrderArgs {
  id: Scalars['Int']['input'];
}

export interface MutationDuplicateBaseTariffArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationDuplicateProductArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationExportOrdersCsvArgs {
  productUuid: Scalars['UUID']['input'];
}

export interface MutationGiveOrdersArgs {
  params?: InputMaybe<OrdersActionNotifyParams>;
  productUuid: Scalars['UUID']['input'];
  tariffUuid: Scalars['UUID']['input'];
  usersUuids: Scalars['UUID']['input'][];
}

export interface MutationLoginArgs {
  input: LoginInput;
}

export interface MutationLoginTgArgs {
  input: LoginTgInput;
}

export interface MutationPayBonusInvoiceArgs {
  params: PayBonusInvoiceInput;
}

export interface MutationRefreshArgs {
  refresh_token: Scalars['String']['input'];
}

export interface MutationRefundOrderArgs {
  orderId: Scalars['Int']['input'];
}

export interface MutationRemoveBaseTariffArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationRemoveBotArgs {
  id: Scalars['Float']['input'];
}

export interface MutationRemoveBotFromOrganizerArgs {
  idBot: Scalars['Float']['input'];
  idOrganizer: Scalars['Float']['input'];
}

export interface MutationRemovePaymentAccountArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationRemoveProductArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationRemoveReferralOrganizerArgs {
  key: Scalars['String']['input'];
}

export interface MutationRemoveTariffArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationRemoveUserFromBlacklistArgs {
  organizerId: Scalars['Float']['input'];
  uuid: Scalars['UUID']['input'];
}

export interface MutationRemoveUserFromGroupArgs {
  uuidGroup: Scalars['UUID']['input'];
  uuidUser: Scalars['UUID']['input'];
}

export interface MutationRemoveUserGroupArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationRenameReferralOrganizerArgs {
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
}

export interface MutationRequestWithdrawalArgs {
  input: WithdrawalOrganizerInput;
}

export interface MutationSaveUserInfoArgs {
  input: SaveUserInfoInput;
}

export interface MutationSignUpArgs {
  input: SignUpInput;
}

export interface MutationSubmitInviteArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationTransferOrderToProductArgs {
  orderId: Scalars['Int']['input'];
  productUuid: Scalars['UUID']['input'];
}

export interface MutationTransferOrderToUserArgs {
  orderId: Scalars['Int']['input'];
  transferUserUuid: Scalars['UUID']['input'];
}

export interface MutationUpdateBaseTariffArgs {
  input: BaseTariffInput;
  uuid: Scalars['UUID']['input'];
}

export interface MutationUpdateOrganizerArgs {
  id: Scalars['Float']['input'];
  input: OrganizerInput;
}

export interface MutationUpdatePaymentAccountArgs {
  input: PaymentAccountInput;
  uuid: Scalars['UUID']['input'];
}

export interface MutationUpdateProductArgs {
  input: ProductInput;
  uuid: Scalars['UUID']['input'];
}

export interface MutationUpdateProductInviteTextsArgs {
  input: ProductInviteTextsInput;
  uuid: Scalars['UUID']['input'];
}

export interface MutationUpdateProductTariffsArgs {
  productUuid: Scalars['UUID']['input'];
  uuids: Scalars['UUID']['input'][];
}

export interface MutationUpdateTariffArgs {
  input: SubTariffInput;
  uuid: Scalars['UUID']['input'];
}

export interface MutationUpdateUserArgs {
  input: UpdateUserInput;
  uuid: Scalars['UUID']['input'];
}

export interface MutationUpdateUserGroupNameArgs {
  name: Scalars['String']['input'];
  uuid: Scalars['UUID']['input'];
}

export interface MutationUpdateUserGroupsBaseTariffArgs {
  userGroupsUuid: Scalars['UUID']['input'][];
  uuid: Scalars['UUID']['input'];
}

export interface MutationUpdateUsersInGroupArgs {
  uuidGroup: Scalars['UUID']['input'];
  uuidUsers: Scalars['UUID']['input'][];
}

export interface MutationUploadFileArgs {
  file: Scalars['Upload']['input'];
}

export interface MutationUploadImagesArgs {
  image: Scalars['Upload']['input'][];
}

export interface MutationVerifyEmailArgs {
  code: Scalars['String']['input'];
}

export interface MutationVerifyEmailLaterArgs {
  email: Scalars['EmailAddress']['input'];
}

export interface NodeIdType {
  id: Scalars['ID']['output'];
}

export interface NodeUuidType {
  uuid: Scalars['UUID']['output'];
}

export interface OrderCountsType {
  __typename?: 'OrderCountsType';
  awaitPay: Scalars['Float']['output'];
  paid: Scalars['Float']['output'];
  refunded: Scalars['Float']['output'];
  transferredProduct: Scalars['Float']['output'];
  transferredUser: Scalars['Float']['output'];
}

export interface OrderFeatureFlagsType {
  __typename?: 'OrderFeatureFlagsType';
  canRefund: Scalars['Boolean']['output'];
  canTransferToProduct: Scalars['Boolean']['output'];
  canTransferToUser: Scalars['Boolean']['output'];
}

export interface OrderInviteResultType {
  __typename?: 'OrderInviteResultType';
  error?: Maybe<Errors>;
  status: Scalars['Boolean']['output'];
  uuid?: Maybe<Scalars['String']['output']>;
  uuidUser: Scalars['UUID']['output'];
}

export enum OrderInviteStatusEnum {
  Cancelled = 'Cancelled',
  Confirmed = 'Confirmed',
  Pending = 'Pending',
  Withdrawn = 'Withdrawn',
}

export type OrderInviteType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'OrderInviteType';
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    product: ProductType;
    status: OrderInviteStatusEnum;
    tariff: SubTariffType;
    updatedAt: Scalars['DateTimeISO']['output'];
    user: UserType;
    uuid: Scalars['UUID']['output'];
  };

export enum OrderSortKeys {
  CompletedAt = 'completedAt',
  CreatedAt = 'createdAt',
  Id = 'id',
  Status = 'status',
  VisitedAt = 'visitedAt',
}

export interface OrderSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: OrderSortKeys;
}

export enum OrderStatusEnum {
  CompletedAndNotVisited = 'CompletedAndNotVisited',
  CompletedAndVisited = 'CompletedAndVisited',
  Deactivated = 'Deactivated',
  NotPaid = 'NotPaid',
  Paid = 'Paid',
  Refunded = 'Refunded',
  TransferredToProduct = 'TransferredToProduct',
  TransferredToUser = 'TransferredToUser',
}

export type OrderType = BaseGraphModel &
  NodeIdType & {
    __typename?: 'OrderType';
    amount: Scalars['Float']['output'];
    completedAt?: Maybe<Scalars['DateTime']['output']>;
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    featureFlags: OrderFeatureFlagsType;
    fixedHoldPercentage: Scalars['Float']['output'];
    id: Scalars['ID']['output'];
    orderInvite?: Maybe<OrderType>;
    organizer: OrganizerType;
    pairOrder?: Maybe<OrderType>;
    payment?: Maybe<PaymentType>;
    product: ProductType;
    referralOrganizer?: Maybe<ReferralOrganizerType>;
    status: OrderStatusEnum;
    tariff: TariffType;
    updatedAt: Scalars['DateTimeISO']['output'];
    user: UserType;
    /** Pay by bonus in this order */
    userBalanceWithdrawal?: Maybe<UserBalanceWithdrawalType>;
    uuid: Scalars['UUID']['output'];
    visitedAt?: Maybe<Scalars['DateTime']['output']>;
  };

export interface OrdersActionNotifyParams {
  botId?: InputMaybe<Scalars['Float']['input']>;
  sendNotifyToBot?: InputMaybe<Scalars['Boolean']['input']>;
  sendNotifyToEmail?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface OrdersFilterInput {
  organizerId?: InputMaybe<Scalars['Int']['input']>;
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
  uuidUser?: InputMaybe<Scalars['UUID']['input']>;
}

export interface OrdersListFilterInput {
  order?: InputMaybe<OrderSortType>;
  pagination?: InputMaybe<PaginationType>;
}

export interface OrdersParamsInput {
  status?: InputMaybe<OrderStatusEnum[]>;
}

export interface OrdersResponse {
  __typename?: 'OrdersResponse';
  count: Scalars['Int']['output'];
  rows: OrderType[];
}

export interface OrganizerFilesType {
  __typename?: 'OrganizerFilesType';
  agent?: Maybe<Scalars['String']['output']>;
  oferta?: Maybe<Scalars['String']['output']>;
  policy?: Maybe<Scalars['String']['output']>;
}

export interface OrganizerInput {
  description: Scalars['String']['input'];
  image: Scalars['String']['input'];
  name: Scalars['String']['input'];
  orderInstructions: OrganizerOrderInstructionsInput;
  query?: InputMaybe<Scalars['String']['input']>;
  socials: OrganizerSocialInput;
  supplierInfo: OrganizerSupplierInfoInput;
  telegram: Scalars['String']['input'];
}

export interface OrganizerListFilterInput {
  order?: InputMaybe<OrganizerSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<OrganizerSearchType>;
}

export interface OrganizerOrderInstructionsInput {
  refund?: InputMaybe<Scalars['String']['input']>;
  transferToProduct?: InputMaybe<Scalars['String']['input']>;
  transferToUser?: InputMaybe<Scalars['String']['input']>;
}

export interface OrganizerOrderInstructionsType {
  __typename?: 'OrganizerOrderInstructionsType';
  refund: Scalars['String']['output'];
  transferToProduct: Scalars['String']['output'];
  transferToUser: Scalars['String']['output'];
}

export enum OrganizerSearchKeys {
  Description = 'description',
  Name = 'name',
  Telegram = 'telegram',
}

export interface OrganizerSearchType {
  search: Scalars['String']['input'];
  searchBy: OrganizerSearchKeys;
}

export interface OrganizerSocialInput {
  facebook?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  telegram?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  vk?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
}

export interface OrganizerSocialType {
  __typename?: 'OrganizerSocialType';
  facebook?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  telegram?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  vk?: Maybe<Scalars['String']['output']>;
  youtube?: Maybe<Scalars['String']['output']>;
}

export enum OrganizerSortKeys {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  Telegram = 'telegram',
}

export interface OrganizerSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: OrganizerSortKeys;
}

export interface OrganizerSupplierInfoInput {
  inn?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
}

export interface OrganizerSupplierInfoType {
  __typename?: 'OrganizerSupplierInfoType';
  inn?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['PhoneNumber']['output']>;
}

export type OrganizerType = BaseGraphModel &
  NodeIdType & {
    __typename?: 'OrganizerType';
    balance: Scalars['Float']['output'];
    bots: BaseBotType[];
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    description: Scalars['String']['output'];
    files: OrganizerFilesType;
    hasTerminalKey: Scalars['Boolean']['output'];
    holdPercentage: Scalars['Int']['output'];
    id: Scalars['ID']['output'];
    image: Scalars['String']['output'];
    name: Scalars['String']['output'];
    orderInstructions: OrganizerOrderInstructionsType;
    ordersCount: Scalars['Int']['output'];
    paymentAccounts: PaymentAccountType[];
    products: ProductType[];
    productsCount: Scalars['Int']['output'];
    query?: Maybe<Scalars['String']['output']>;
    referralBalance: Scalars['Float']['output'];
    salesBalance: Scalars['Float']['output'];
    socials: OrganizerSocialType;
    statsPayments: AmountStatsType;
    supplierInfo: OrganizerSupplierInfoType;
    telegram: Scalars['String']['output'];
    updatedAt: Scalars['DateTimeISO']['output'];
    userBalance: Scalars['Float']['output'];
    withdrawals: WithdrawalType[];
  };

export interface OrganizerTypeOrderInstructionsArgs {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface OrganizerTypeSocialsArgs {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface OrganizersResponse {
  __typename?: 'OrganizersResponse';
  count: Scalars['Int']['output'];
  rows: OrganizerType[];
}

export interface PaginationType {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}

export interface PayBonusInvoiceInput {
  amount: Scalars['Float']['input'];
  paymentUuid: Scalars['UUID']['input'];
}

export interface PaymentAccountInput {
  accountNumber: Scalars['String']['input'];
  bik: Scalars['String']['input'];
  inn: Scalars['String']['input'];
  name: Scalars['String']['input'];
}

export type PaymentAccountType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'PaymentAccountType';
    accountNumber: Scalars['String']['output'];
    bik: Scalars['String']['output'];
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    inn: Scalars['String']['output'];
    name: Scalars['String']['output'];
    updatedAt: Scalars['DateTimeISO']['output'];
    uuid: Scalars['UUID']['output'];
  };

export enum PaymentSortKeys {
  CreatedAt = 'createdAt',
  Price = 'price',
}

export interface PaymentSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: PaymentSortKeys;
}

export enum PaymentStatusEnum {
  Canceled = 'Canceled',
  Created = 'Created',
  Failed = 'Failed',
  Overdue = 'Overdue',
  Pending = 'Pending',
  Success = 'Success',
}

export type PaymentType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'PaymentType';
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    invoiceQrLink?: Maybe<Scalars['String']['output']>;
    invoice_url?: Maybe<Scalars['String']['output']>;
    order: OrderType;
    payment_uuid?: Maybe<Scalars['String']['output']>;
    price: Scalars['Float']['output'];
    status: PaymentStatusEnum;
    updatedAt: Scalars['DateTimeISO']['output'];
    user: UserType;
    uuid: Scalars['UUID']['output'];
  };

export interface PaymentsArgsInput {
  organizerId?: InputMaybe<Scalars['Int']['input']>;
  uuidUser?: InputMaybe<Scalars['UUID']['input']>;
}

export interface PaymentsListFilterInput {
  order?: InputMaybe<PaymentSortType>;
  pagination?: InputMaybe<PaginationType>;
}

export interface PaymentsResponse {
  __typename?: 'PaymentsResponse';
  count: Scalars['Int']['output'];
  rows: PaymentType[];
}

export interface PreviewProductInput {
  active: Scalars['Boolean']['input'];
  backButtonText?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
}

export interface PreviewProductType {
  __typename?: 'PreviewProductType';
  active: Scalars['Boolean']['output'];
  backButtonText?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface ProductCountsType {
  __typename?: 'ProductCountsType';
  activeCount: Scalars['Float']['output'];
  archiveCount: Scalars['Float']['output'];
  count: Scalars['Float']['output'];
  draftCount: Scalars['Float']['output'];
}

export interface ProductFeatureFlagsInput {
  allowedNumberForTransferToProductOrder: Scalars['Float']['input'];
  durationBeforeStartForRefundOrder: Scalars['String']['input'];
  durationBeforeStartForTransferToUserOrder: Scalars['String']['input'];
}

export interface ProductFeatureFlagsType {
  __typename?: 'ProductFeatureFlagsType';
  allowedNumberForTransferToProductOrder: Scalars['Float']['output'];
  durationBeforeStartForRefundOrder: Scalars['ISO8601Duration']['output'];
  durationBeforeStartForTransferToUserOrder: Scalars['ISO8601Duration']['output'];
}

export interface ProductFilter {
  organizerId: Scalars['Int']['input'];
}

export interface ProductInput {
  activeAcquiring: Scalars['Boolean']['input'];
  address?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  endDate: Scalars['DateTime']['input'];
  faq?: InputMaybe<Scalars['String']['input']>;
  featureFlags: ProductFeatureFlagsInput;
  img: Scalars['String']['input'];
  invoiceLifetime: Scalars['String']['input'];
  manualBuyText?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  preview: PreviewProductInput;
  query?: InputMaybe<Scalars['String']['input']>;
  route: RouteProductInput;
  salesStartedDate?: InputMaybe<Scalars['DateTime']['input']>;
  salesStartedText?: InputMaybe<Scalars['String']['input']>;
  startedDate: Scalars['DateTime']['input'];
  successBuyText?: InputMaybe<Scalars['String']['input']>;
  tariffsEmptyText?: InputMaybe<Scalars['String']['input']>;
  tariffsText?: InputMaybe<Scalars['String']['input']>;
  tariffsUnavailableText?: InputMaybe<Scalars['String']['input']>;
  transferOrderToUserSuccessText?: InputMaybe<Scalars['String']['input']>;
}

export interface ProductInviteTextsInput {
  inviteOrderSuccessText?: InputMaybe<Scalars['String']['input']>;
  inviteOrderText?: InputMaybe<Scalars['String']['input']>;
}

export interface ProductListFilterInput {
  order?: InputMaybe<ProductSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<ProductSearchType>;
}

export enum ProductSearchKeysEnum {
  Description = 'description',
  Name = 'name',
}

export interface ProductSearchType {
  search: Scalars['String']['input'];
  searchBy: ProductSearchKeysEnum;
}

export enum ProductSortKeys {
  CreatedAt = 'createdAt',
  EndDate = 'endDate',
  Name = 'name',
  StartedDate = 'startedDate',
  Status = 'status',
  UpdatedAt = 'updatedAt',
}

export interface ProductSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: ProductSortKeys;
}

export type ProductType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'ProductType';
    activeAcquiring: Scalars['Boolean']['output'];
    address?: Maybe<Scalars['String']['output']>;
    canBeRemoved: Scalars['Boolean']['output'];
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    description: Scalars['String']['output'];
    endDate: Scalars['DateTime']['output'];
    faq?: Maybe<Scalars['String']['output']>;
    featureFlags: ProductFeatureFlagsType;
    img: Scalars['String']['output'];
    inviteOrderSuccessText: Scalars['String']['output'];
    inviteOrderText: Scalars['String']['output'];
    invoiceLifetime: Scalars['ISO8601Duration']['output'];
    manualBuyText?: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    organizer: OrganizerType;
    preview: PreviewProductType;
    purchasedCount: Scalars['Float']['output'];
    query?: Maybe<Scalars['String']['output']>;
    route: RouteProductType;
    salesStartedDate?: Maybe<Scalars['DateTime']['output']>;
    salesStartedText?: Maybe<Scalars['String']['output']>;
    startedDate: Scalars['DateTime']['output'];
    statsPayments: AmountStatsType;
    status: StatusEnum;
    successBuyText: Scalars['String']['output'];
    tariffs: TariffType[];
    tariffsEmptyText: Scalars['String']['output'];
    tariffsText: Scalars['String']['output'];
    tariffsUnavailableText: Scalars['String']['output'];
    transferOrderToUserSuccessText: Scalars['String']['output'];
    updatedAt: Scalars['DateTimeISO']['output'];
    uuid: Scalars['UUID']['output'];
    visitedCount: Scalars['Float']['output'];
  };

export interface ProductTypeFaqArgs {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProductTypeInviteOrderSuccessTextArgs {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProductTypeInviteOrderTextArgs {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProductTypeSalesStartedTextArgs {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProductTypeSuccessBuyTextArgs {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProductTypeTariffsEmptyTextArgs {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProductTypeTariffsTextArgs {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProductTypeTariffsUnavailableTextArgs {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProductTypeTransferOrderToUserSuccessTextArgs {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProductsResponse {
  __typename?: 'ProductsResponse';
  count: Scalars['Int']['output'];
  rows: ProductType[];
}

export interface Query {
  __typename?: 'Query';
  allowedUserGroupsForBaseTariff: UserGroupType[];
  baseTariff: BaseTariffType;
  baseTariffsList: BaseTariffsResponse;
  baseTexts: BaseTextsType[];
  blacklist: UsersResponse;
  botsBySecretKey: BotType[];
  botsList: BotsResponse;
  checkCanRepeatOrder: Scalars['Boolean']['output'];
  checkHasOrder: Scalars['Boolean']['output'];
  me: UserType;
  order: OrderType;
  orderByProductTariff: OrderType;
  orderByUuid: OrderType;
  orderInvite: OrderInviteType;
  orderInviteByProduct: OrderInviteType;
  /** Getting a list of orders for the user */
  orders: OrdersResponse;
  ordersList: OrdersResponse;
  ordersReferralOrganizerList: OrdersResponse;
  /** Method for the client part of the bot. For administrators to use query - organizerAdmin */
  organizer: OrganizerType;
  organizerAdmin: OrganizerType;
  organizerByQuery: OrganizerType;
  organizerShortcodes: Scalars['String']['output'][];
  organizers: OrganizersResponse;
  organizersList: OrganizersResponse;
  payment: PaymentType;
  payments: PaymentsResponse;
  paymentsByOrganizer: PaymentAccountType[];
  paymentsList: PaymentsResponse;
  product: ProductType;
  productByQuery: ProductType;
  productShortcodes: Scalars['String']['output'][];
  products: ProductsResponse;
  productsCount: ProductCountsType;
  productsForTransfer: ProductType[];
  productsList: ProductsResponse;
  referralOrganizer: ReferralOrganizerType;
  referralProfit: Scalars['Float']['output'];
  referralsOrganizerList: ReferralsOrganizerResponse;
  searchUserByOrganizer?: Maybe<UserType>;
  statsOrders: AmountStatsType;
  statsPayments: AmountStatsType;
  statsReferralWithdrawal: WithdrawalAmountStatsType;
  statsSalesWithdrawal: WithdrawalAmountStatsType;
  statsUsers: UsersGenderCountsType;
  statsUsersWithActiveOrders: UsersGenderCountsType;
  statsUsersWithOrders: UsersGenderCountsType;
  subTariff: SubTariffType;
  subTariffsList: SubTariffsResponse;
  tariff: TariffType;
  tariffByQuery: TariffType;
  tariffs: TariffsResponse;
  user?: Maybe<UserType>;
  userBalance: UserBalanceType;
  userGroups: UserGroupsResponse;
  users: UsersResponse;
  usersByGroup: UsersResponse;
  usersByProduct: UsersResponse;
  usersControlOrganizer: UsersResponse;
  withdrawalsByOrganizer: WithdrawalsResponse;
}

export interface QueryAllowedUserGroupsForBaseTariffArgs {
  uuid: Scalars['UUID']['input'];
}

export interface QueryBaseTariffArgs {
  uuid: Scalars['UUID']['input'];
}

export interface QueryBaseTariffsListArgs {
  filters?: InputMaybe<BaseTariffListFilterInput>;
  idOrganizer?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<StatusEnum[]>;
}

export interface QueryBaseTextsArgs {
  keys: BaseTextsKeysEnum[];
}

export interface QueryBlacklistArgs {
  filters?: InputMaybe<UserListFilterInput>;
  organizerId: Scalars['Int']['input'];
}

export interface QueryBotsListArgs {
  filters?: InputMaybe<BotListFilterInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  params?: InputMaybe<BotListParamsInput>;
}

export interface QueryCheckCanRepeatOrderArgs {
  productUuid: Scalars['UUID']['input'];
  tariffUuid: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
}

export interface QueryCheckHasOrderArgs {
  productUuid: Scalars['UUID']['input'];
  tariffUuid?: InputMaybe<Scalars['UUID']['input']>;
  userUuid: Scalars['UUID']['input'];
}

export interface QueryOrderArgs {
  id: Scalars['Int']['input'];
}

export interface QueryOrderByProductTariffArgs {
  status?: InputMaybe<OrderStatusEnum[]>;
  statusPayment?: InputMaybe<PaymentStatusEnum[]>;
  uuidProduct: Scalars['UUID']['input'];
  uuidTariff?: InputMaybe<Scalars['UUID']['input']>;
}

export interface QueryOrderByUuidArgs {
  uuid: Scalars['UUID']['input'];
}

export interface QueryOrderInviteArgs {
  uuid: Scalars['UUID']['input'];
}

export interface QueryOrderInviteByProductArgs {
  uuidProduct: Scalars['UUID']['input'];
}

export interface QueryOrdersArgs {
  filters?: InputMaybe<OrdersListFilterInput>;
  params?: InputMaybe<OrdersParamsInput>;
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
}

export interface QueryOrdersListArgs {
  args?: InputMaybe<OrdersFilterInput>;
  filters?: InputMaybe<OrdersListFilterInput>;
  params?: InputMaybe<OrdersParamsInput>;
}

export interface QueryOrdersReferralOrganizerListArgs {
  args?: InputMaybe<OrdersFilterInput>;
  filters?: InputMaybe<OrdersListFilterInput>;
}

export interface QueryOrganizerArgs {
  id: Scalars['Int']['input'];
}

export interface QueryOrganizerAdminArgs {
  id: Scalars['Int']['input'];
}

export interface QueryOrganizerByQueryArgs {
  query: Scalars['String']['input'];
}

export interface QueryOrganizersArgs {
  filters?: InputMaybe<OrganizerListFilterInput>;
}

export interface QueryOrganizersListArgs {
  filters?: InputMaybe<OrganizerListFilterInput>;
  status?: InputMaybe<StatusEnum[]>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface QueryPaymentArgs {
  uuid: Scalars['UUID']['input'];
}

export interface QueryPaymentsArgs {
  filters?: InputMaybe<PaymentsListFilterInput>;
}

export interface QueryPaymentsByOrganizerArgs {
  idOrganizer: Scalars['Int']['input'];
}

export interface QueryPaymentsListArgs {
  args?: InputMaybe<PaymentsArgsInput>;
  filters?: InputMaybe<PaymentsListFilterInput>;
  status?: PaymentStatusEnum[];
}

export interface QueryProductArgs {
  uuid: Scalars['UUID']['input'];
}

export interface QueryProductByQueryArgs {
  query: Scalars['String']['input'];
}

export interface QueryProductsArgs {
  filters?: InputMaybe<ProductListFilterInput>;
  params: ProductFilter;
}

export interface QueryProductsCountArgs {
  idOrganizer?: InputMaybe<Scalars['Float']['input']>;
}

export interface QueryProductsForTransferArgs {
  currentTariffUuid: Scalars['UUID']['input'];
  organizerId: Scalars['Int']['input'];
}

export interface QueryProductsListArgs {
  filters?: InputMaybe<ProductListFilterInput>;
  params?: InputMaybe<ProductFilter>;
  status?: InputMaybe<StatusEnum[]>;
}

export interface QueryReferralOrganizerArgs {
  key: Scalars['String']['input'];
}

export interface QueryReferralProfitArgs {
  idOrganizer: Scalars['Int']['input'];
}

export interface QueryReferralsOrganizerListArgs {
  filters?: InputMaybe<ReferralOrganizerListFilterInput>;
  params?: InputMaybe<ReferralOrganizerParams>;
  status?: InputMaybe<StatusEnum[]>;
}

export interface QuerySearchUserByOrganizerArgs {
  idOrganizer: Scalars['Int']['input'];
  input: Scalars['String']['input'];
  params?: InputMaybe<SearchUserParamsInput>;
}

export interface QueryStatsOrdersArgs {
  organizerId: Scalars['Int']['input'];
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
}

export interface QueryStatsPaymentsArgs {
  organizerId: Scalars['Int']['input'];
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
}

export interface QueryStatsReferralWithdrawalArgs {
  idOrganizer: Scalars['Int']['input'];
}

export interface QueryStatsSalesWithdrawalArgs {
  idOrganizer: Scalars['Int']['input'];
}

export interface QueryStatsUsersArgs {
  organizerId: Scalars['Int']['input'];
}

export interface QueryStatsUsersWithActiveOrdersArgs {
  organizerId: Scalars['Int']['input'];
  productUuid?: InputMaybe<Scalars['UUID']['input']>;
}

export interface QueryStatsUsersWithOrdersArgs {
  organizerId: Scalars['Int']['input'];
  productUuid?: InputMaybe<Scalars['UUID']['input']>;
}

export interface QuerySubTariffArgs {
  uuid: Scalars['UUID']['input'];
}

export interface QuerySubTariffsListArgs {
  filters?: InputMaybe<SubTariffListFilterInput>;
  productUuid: Scalars['UUID']['input'];
  status?: InputMaybe<StatusEnum[]>;
}

export interface QueryTariffArgs {
  uuid: Scalars['UUID']['input'];
}

export interface QueryTariffByQueryArgs {
  productQuery: Scalars['String']['input'];
  tariffQuery: Scalars['String']['input'];
}

export interface QueryTariffsArgs {
  filters?: InputMaybe<TariffListFilterInput>;
  uuidProduct: Scalars['UUID']['input'];
}

export interface QueryUserArgs {
  uuid: Scalars['UUID']['input'];
}

export interface QueryUserBalanceArgs {
  organizerId: Scalars['Int']['input'];
}

export interface QueryUserGroupsArgs {
  filters?: InputMaybe<UserGroupsListFilterInput>;
  idOrganizer: Scalars['Int']['input'];
}

export interface QueryUsersArgs {
  args: FilterUsersInput;
  filters?: InputMaybe<UserListFilterInput>;
  organizerId: Scalars['Int']['input'];
}

export interface QueryUsersByGroupArgs {
  filters?: InputMaybe<UserListFilterInput>;
  groupUuid: Scalars['UUID']['input'];
}

export interface QueryUsersByProductArgs {
  filters?: InputMaybe<UserListFilterInput>;
  uuidProduct: Scalars['UUID']['input'];
}

export interface QueryUsersControlOrganizerArgs {
  filters?: InputMaybe<UserListFilterInput>;
  organizerId: Scalars['Int']['input'];
}

export interface QueryWithdrawalsByOrganizerArgs {
  filters?: InputMaybe<WithdrawalListFilterInput>;
  from?: InputMaybe<WithdrawalFromEnum>;
  idOrganizer: Scalars['Int']['input'];
}

export interface ReferralOrganizerInput {
  idOrganizer: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  percentage: Scalars['Float']['input'];
  uuidProduct: Scalars['String']['input'];
}

export interface ReferralOrganizerListFilterInput {
  order?: InputMaybe<ReferralOrganizerSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<ReferralOrganizerSearchType>;
}

export interface ReferralOrganizerParams {
  idUsedOrganizer?: InputMaybe<Scalars['Int']['input']>;
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
}

export enum ReferralOrganizerSearchKeys {
  Name = 'name',
}

export interface ReferralOrganizerSearchType {
  search: Scalars['String']['input'];
  searchBy: ReferralOrganizerSearchKeys;
}

export enum ReferralOrganizerSortKeys {
  CreatedAt = 'createdAt',
  Key = 'key',
  Name = 'name',
  Percentage = 'percentage',
  Status = 'status',
  UpdatedAt = 'updatedAt',
}

export interface ReferralOrganizerSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: ReferralOrganizerSortKeys;
}

export type ReferralOrganizerType = BaseGraphModel & {
  __typename?: 'ReferralOrganizerType';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  ordersCount: Scalars['Int']['output'];
  percentage: Scalars['Float']['output'];
  product: ProductType;
  status: StatusEnum;
  updatedAt: Scalars['DateTimeISO']['output'];
  usedOrganizer: OrganizerType;
};

export interface ReferralsOrganizerResponse {
  __typename?: 'ReferralsOrganizerResponse';
  count: Scalars['Int']['output'];
  rows: ReferralOrganizerType[];
}

export enum RoleEnum {
  Admin = 'Admin',
  Organizer = 'Organizer',
  User = 'User',
}

export interface RouteProductInput {
  active: Scalars['Boolean']['input'];
  backButtonText?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
}

export interface RouteProductType {
  __typename?: 'RouteProductType';
  active: Scalars['Boolean']['output'];
  backButtonText?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface SaveUserInfoInput {
  first_name: Scalars['String']['input'];
  gender: GenderEnum;
  last_name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
}

export interface SearchUserParamsInput {
  fullUsernameMatch?: InputMaybe<Scalars['Boolean']['input']>;
  offSearchByNames?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface SignUpInput {
  email?: InputMaybe<Scalars['String']['input']>;
  first_name: Scalars['String']['input'];
  gender?: InputMaybe<GenderEnum>;
  language?: LanguageEnum;
  last_name?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  tgId: Scalars['Float']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
}

export enum SortEnum {
  Asc = 'Asc',
  Desc = 'Desc',
}

export enum StatusEnum {
  Active = 'Active',
  Archive = 'Archive',
  Draft = 'Draft',
}

export interface SubTariffInput {
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ordersCount?: InputMaybe<Scalars['Float']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  specialPrice?: InputMaybe<Scalars['Float']['input']>;
  startedDate?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SubTariffListFilterInput {
  order?: InputMaybe<SubTariffSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<SubTariffSearchType>;
}

export enum SubTariffSearchKeys {
  Description = 'description',
  Name = 'name',
  Type = 'type',
}

export interface SubTariffSearchType {
  search: Scalars['String']['input'];
  searchBy: SubTariffSearchKeys;
}

export enum SubTariffSortKeys {
  CreatedAt = 'createdAt',
  EndDate = 'endDate',
  Name = 'name',
  OrdersCount = 'ordersCount',
  Price = 'price',
  Query = 'query',
  SpecialPrice = 'specialPrice',
  StartedDate = 'startedDate',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  Visible = 'visible',
}

export interface SubTariffSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: SubTariffSortKeys;
}

export type SubTariffType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'SubTariffType';
    availableOrdersCount?: Maybe<Scalars['Float']['output']>;
    baseTariff?: Maybe<BaseTariffType>;
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    description?: Maybe<Scalars['String']['output']>;
    endDate?: Maybe<Scalars['DateTime']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    ordersCount?: Maybe<Scalars['Float']['output']>;
    price?: Maybe<Scalars['Float']['output']>;
    query?: Maybe<Scalars['String']['output']>;
    specialPrice?: Maybe<Scalars['Float']['output']>;
    startedDate?: Maybe<Scalars['DateTime']['output']>;
    statsOrdersCount: OrderCountsType;
    status: StatusEnum;
    updatedAt: Scalars['DateTimeISO']['output'];
    uuid: Scalars['UUID']['output'];
  };

export interface SubTariffsResponse {
  __typename?: 'SubTariffsResponse';
  count: Scalars['Int']['output'];
  rows: SubTariffType[];
}

export interface TariffFeatureFlagsInput {
  bonusPay: Scalars['Boolean']['input'];
  refundOrder: Scalars['Boolean']['input'];
  repeatBuyOrder: Scalars['Boolean']['input'];
  transferToProductOrder: Scalars['Boolean']['input'];
  transferToUserOrder: Scalars['Boolean']['input'];
}

export interface TariffFeatureFlagsType {
  __typename?: 'TariffFeatureFlagsType';
  bonusPay: Scalars['Boolean']['output'];
  refundOrder: Scalars['Boolean']['output'];
  repeatBuyOrder: Scalars['Boolean']['output'];
  transferToProductOrder: Scalars['Boolean']['output'];
  transferToUserOrder: Scalars['Boolean']['output'];
}

export interface TariffListFilterInput {
  pagination?: InputMaybe<PaginationType>;
}

export type TariffType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'TariffType';
    availableOrdersCount?: Maybe<Scalars['Float']['output']>;
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    description?: Maybe<Scalars['String']['output']>;
    endDate?: Maybe<Scalars['DateTime']['output']>;
    featureFlags: TariffFeatureFlagsType;
    name: Scalars['String']['output'];
    ordersCount?: Maybe<Scalars['Float']['output']>;
    paidOrdersCount: Scalars['Float']['output'];
    price: Scalars['Float']['output'];
    query: Scalars['Int']['output'];
    specialPrice?: Maybe<Scalars['Float']['output']>;
    startedDate?: Maybe<Scalars['DateTime']['output']>;
    type: TariffTypeEnum;
    updatedAt: Scalars['DateTimeISO']['output'];
    uuid: Scalars['UUID']['output'];
    visible: Scalars['Boolean']['output'];
  };

export enum TariffTypeEnum {
  AnyGender = 'AnyGender',
  Female = 'Female',
  Male = 'Male',
  Pair = 'Pair',
}

export interface TariffsResponse {
  __typename?: 'TariffsResponse';
  count: Scalars['Int']['output'];
  rows: TariffType[];
}

export interface UpdateUserInput {
  email?: InputMaybe<Scalars['String']['input']>;
  first_name: Scalars['String']['input'];
  gender?: InputMaybe<GenderEnum>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
}

export type UserBalanceType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'UserBalanceType';
    amount: Scalars['Float']['output'];
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    organizer: OrganizerType;
    updatedAt: Scalars['DateTimeISO']['output'];
    user: UserType;
    uuid: Scalars['UUID']['output'];
  };

export type UserBalanceWithdrawalType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'UserBalanceWithdrawalType';
    amount: Scalars['Float']['output'];
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    organizer: OrganizerType;
    updatedAt: Scalars['DateTimeISO']['output'];
    user: UserType;
    uuid: Scalars['UUID']['output'];
  };

export type UserGroupType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'UserGroupType';
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    name: Scalars['String']['output'];
    organizer: OrganizerType;
    updatedAt: Scalars['DateTimeISO']['output'];
    usersCount: Scalars['Int']['output'];
    uuid: Scalars['UUID']['output'];
  };

export interface UserGroupsListFilterInput {
  order?: InputMaybe<UserGroupsSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<UserGroupsSearchType>;
}

export interface UserGroupsResponse {
  __typename?: 'UserGroupsResponse';
  count: Scalars['Int']['output'];
  rows: UserGroupType[];
}

export enum UserGroupsSearchKeys {
  Name = 'name',
}

export interface UserGroupsSearchType {
  search: Scalars['String']['input'];
  searchBy: UserGroupsSearchKeys;
}

export enum UserGroupsSortKeys {
  CreatedAt = 'createdAt',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid',
}

export interface UserGroupsSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: UserGroupsSortKeys;
}

export interface UserListFilterInput {
  order?: InputMaybe<UserSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<Scalars['String']['input']>;
}

export enum UserSortKeys {
  CreatedAt = 'createdAt',
  Email = 'email',
  FirstName = 'first_name',
  Gender = 'gender',
  LastName = 'last_name',
  Phone = 'phone',
  TgId = 'tgId',
  Username = 'username',
}

export interface UserSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: UserSortKeys;
}

export type UserType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'UserType';
    activatedOrdersCount: Scalars['Int']['output'];
    balance: Scalars['Float']['output'];
    controlOrganizerId?: Maybe<Scalars['Float']['output']>;
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    email?: Maybe<Scalars['EmailAddress']['output']>;
    first_name: Scalars['String']['output'];
    gender?: Maybe<GenderEnum>;
    hasInviteByProduct: Scalars['Boolean']['output'];
    hasOrderByProduct: Scalars['Boolean']['output'];
    hasTariffAccess?: Maybe<Scalars['Boolean']['output']>;
    isBlocked: Scalars['Boolean']['output'];
    language: LanguageEnum;
    last_name?: Maybe<Scalars['String']['output']>;
    ordersCount: Scalars['Int']['output'];
    organizers: OrganizerType[];
    paymentsCount: Scalars['Int']['output'];
    phone?: Maybe<Scalars['PhoneNumber']['output']>;
    role: RoleEnum;
    statsPayments: AmountStatsType;
    submittedEmail: Scalars['Boolean']['output'];
    tgId?: Maybe<Scalars['Float']['output']>;
    updatedAt: Scalars['DateTimeISO']['output'];
    username?: Maybe<Scalars['String']['output']>;
    uuid: Scalars['UUID']['output'];
  };

export interface UserTypeBalanceArgs {
  idOrganizer: Scalars['Int']['input'];
}

export interface UserTypeHasInviteByProductArgs {
  productUuid?: InputMaybe<Scalars['UUID']['input']>;
}

export interface UserTypeHasOrderByProductArgs {
  productUuid?: InputMaybe<Scalars['UUID']['input']>;
}

export interface UserTypeIsBlockedArgs {
  idOrganizer: Scalars['Int']['input'];
}

export interface UsersGenderCountsType {
  __typename?: 'UsersGenderCountsType';
  count: Scalars['Int']['output'];
  femaleCount: Scalars['Int']['output'];
  maleCount: Scalars['Int']['output'];
}

export interface UsersResponse {
  __typename?: 'UsersResponse';
  count: Scalars['Int']['output'];
  rows: UserType[];
}

export interface WithdrawalAmountStatsType {
  __typename?: 'WithdrawalAmountStatsType';
  total: Scalars['Float']['output'];
}

export enum WithdrawalFromEnum {
  ReferralBalance = 'ReferralBalance',
  SalesBalance = 'SalesBalance',
}

export interface WithdrawalListFilterInput {
  order?: InputMaybe<WithdrawalSortType>;
  pagination?: InputMaybe<PaginationType>;
}

export interface WithdrawalOrganizerInput {
  amount: Scalars['Float']['input'];
  from: WithdrawalFromEnum;
  organizerId: Scalars['Int']['input'];
  uuidPaymentAccount: Scalars['UUID']['input'];
}

export enum WithdrawalSortKeys {
  Amount = 'amount',
  CreatedAt = 'createdAt',
  Status = 'status',
  UpdatedAt = 'updatedAt',
}

export interface WithdrawalSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: WithdrawalSortKeys;
}

export enum WithdrawalStatusEnum {
  Cancelled = 'Cancelled',
  Executed = 'Executed',
  Failed = 'Failed',
  InProgress = 'InProgress',
}

export type WithdrawalType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'WithdrawalType';
    amount: Scalars['Float']['output'];
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    from: WithdrawalFromEnum;
    idOrganizer: Scalars['Int']['output'];
    organizer: OrganizerType;
    paymentAccount: PaymentAccountType;
    status: WithdrawalStatusEnum;
    updatedAt: Scalars['DateTimeISO']['output'];
    uuid: Scalars['UUID']['output'];
  };

export interface WithdrawalsResponse {
  __typename?: 'WithdrawalsResponse';
  count: Scalars['Int']['output'];
  rows: WithdrawalType[];
}

export interface JwtFieldsFragment {
  __typename?: 'JwtType';
  access_token: string;
  refresh_token: string;
}

export type LoginTgMutationVariables = Exact<{
  tgId: Scalars['Float']['input'];
  password: Scalars['String']['input'];
}>;

export interface LoginTgMutation {
  __typename?: 'Mutation';
  loginTg: {
    __typename?: 'JwtType';
    access_token: string;
    refresh_token: string;
  };
}

export type LoginMutationVariables = Exact<{
  login: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export interface LoginMutation {
  __typename?: 'Mutation';
  login: {
    __typename?: 'JwtType';
    access_token: string;
    refresh_token: string;
  };
}

export type RefreshMutationVariables = Exact<{
  refresh_token: Scalars['String']['input'];
}>;

export interface RefreshMutation {
  __typename?: 'Mutation';
  refresh: {
    __typename?: 'JwtType';
    access_token: string;
    refresh_token: string;
  };
}

export type MeQueryVariables = Exact<Record<string, never>>;

export interface MeQuery {
  __typename?: 'Query';
  me: {
    __typename?: 'UserType';
    uuid: any;
    username?: string | null;
    first_name: string;
    last_name?: string | null;
    role: RoleEnum;
    email?: any | null;
    phone?: any | null;
    controlOrganizerId?: number | null;
  };
}

export type OrderQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface OrderQuery {
  __typename?: 'Query';
  orderByUuid: {
    __typename?: 'OrderType';
    createdAt: any;
    id: number;
    uuid: any;
    status: OrderStatusEnum;
    fixedHoldPercentage: number;
    user: {
      __typename?: 'UserType';
      username?: string | null;
      first_name: string;
      last_name?: string | null;
      gender?: GenderEnum | null;
      email?: any | null;
      phone?: any | null;
    };
    pairOrder?: {
      __typename?: 'OrderType';
      user: {
        __typename?: 'UserType';
        uuid: any;
        first_name: string;
        last_name?: string | null;
        username?: string | null;
        gender?: GenderEnum | null;
      };
      payment?: { __typename?: 'PaymentType'; price: number } | null;
    } | null;
    payment?: { __typename?: 'PaymentType'; price: number } | null;
    product: { __typename?: 'ProductType'; uuid: any; name: string };
    tariff: { __typename?: 'TariffType'; name: string; price: number };
  };
}

export type UserOrdersQueryVariables = Exact<{
  filter?: InputMaybe<OrdersListFilterInput>;
  args?: InputMaybe<OrdersFilterInput>;
}>;

export interface UserOrdersQuery {
  __typename?: 'Query';
  ordersList: {
    __typename?: 'OrdersResponse';
    count: number;
    rows: {
      __typename?: 'OrderType';
      id: number;
      uuid: any;
      status: OrderStatusEnum;
      product: { __typename?: 'ProductType'; name: string; startedDate: Date };
      tariff: { __typename?: 'TariffType'; name: string };
    }[];
  };
}

export type ActivateOrderMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface ActivateOrderMutation {
  __typename?: 'Mutation';
  activateOrder: any;
}

export type DeactivateOrderMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface DeactivateOrderMutation {
  __typename?: 'Mutation';
  deactivateOrder: any;
}

export interface EventsFieldsFragment {
  __typename?: 'ProductsResponse';
  count: number;
  rows: {
    __typename?: 'ProductType';
    uuid: any;
    startedDate: Date;
    name: string;
    purchasedCount: number;
    visitedCount: number;
  }[];
}

export type EventsQueryVariables = Exact<{
  filters?: InputMaybe<ProductListFilterInput>;
  organizerId: Scalars['Int']['input'];
  status?: InputMaybe<StatusEnum[] | StatusEnum>;
}>;

export interface EventsQuery {
  __typename?: 'Query';
  productsList: {
    __typename?: 'ProductsResponse';
    count: number;
    rows: {
      __typename?: 'ProductType';
      uuid: any;
      startedDate: Date;
      name: string;
      purchasedCount: number;
      visitedCount: number;
    }[];
  };
}

export type EventQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface EventQuery {
  __typename?: 'Query';
  product: {
    __typename?: 'ProductType';
    uuid: any;
    name: string;
    description: string;
    startedDate: Date;
    purchasedCount: number;
    visitedCount: number;
  };
}

export type UsersQueryVariables = Exact<{
  filters?: InputMaybe<UserListFilterInput>;
  uuidProduct: Scalars['UUID']['input'];
}>;

export interface UsersQuery {
  __typename?: 'Query';
  usersByProduct: {
    __typename?: 'UsersResponse';
    count: number;
    rows: {
      __typename?: 'UserType';
      uuid: any;
      createdAt: any;
      first_name: string;
      last_name?: string | null;
      username?: string | null;
      email?: any | null;
      phone?: any | null;
      gender?: GenderEnum | null;
      ordersCount: number;
      activatedOrdersCount: number;
    }[];
  };
}

export type UserQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface UserQuery {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserType';
    uuid: any;
    createdAt: any;
    first_name: string;
    last_name?: string | null;
    username?: string | null;
    email?: any | null;
    phone?: any | null;
    gender?: GenderEnum | null;
    ordersCount: number;
  } | null;
}

export const JwtFieldsFragmentDoc = gql`
  fragment JwtFields on JwtType {
    access_token
    refresh_token
  }
`;
export const EventsFieldsFragmentDoc = gql`
  fragment EventsFields on ProductsResponse {
    rows {
      uuid
      startedDate
      name
      purchasedCount
      visitedCount
    }
    count
  }
`;
export const LoginTgDocument = gql`
  mutation loginTg($tgId: Float!, $password: String!) {
    loginTg(input: { tgId: $tgId, password: $password }) {
      ...JwtFields
    }
  }
  ${JwtFieldsFragmentDoc}
`;
export type LoginTgMutationFn = Apollo.MutationFunction<
  LoginTgMutation,
  LoginTgMutationVariables
>;

/**
 * __useLoginTgMutation__
 *
 * To run a mutation, you first call `useLoginTgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginTgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginTgMutation, { data, loading, error }] = useLoginTgMutation({
 *   variables: {
 *      tgId: // value for 'tgId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginTgMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginTgMutation,
    LoginTgMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<LoginTgMutation, LoginTgMutationVariables>(
    LoginTgDocument,
    options,
  );
}
export type LoginTgMutationHookResult = ReturnType<typeof useLoginTgMutation>;
export type LoginTgMutationResult = Apollo.MutationResult<LoginTgMutation>;
export type LoginTgMutationOptions = Apollo.BaseMutationOptions<
  LoginTgMutation,
  LoginTgMutationVariables
>;
export const LoginDocument = gql`
  mutation login($login: String!, $password: String!) {
    login(input: { login: $login, password: $password }) {
      ...JwtFields
    }
  }
  ${JwtFieldsFragmentDoc}
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      login: // value for 'login'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options,
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const RefreshDocument = gql`
  mutation refresh($refresh_token: String!) {
    refresh(refresh_token: $refresh_token) {
      ...JwtFields
    }
  }
  ${JwtFieldsFragmentDoc}
`;
export type RefreshMutationFn = Apollo.MutationFunction<
  RefreshMutation,
  RefreshMutationVariables
>;

/**
 * __useRefreshMutation__
 *
 * To run a mutation, you first call `useRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshMutation, { data, loading, error }] = useRefreshMutation({
 *   variables: {
 *      refresh_token: // value for 'refresh_token'
 *   },
 * });
 */
export function useRefreshMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshMutation,
    RefreshMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<RefreshMutation, RefreshMutationVariables>(
    RefreshDocument,
    options,
  );
}
export type RefreshMutationHookResult = ReturnType<typeof useRefreshMutation>;
export type RefreshMutationResult = Apollo.MutationResult<RefreshMutation>;
export type RefreshMutationOptions = Apollo.BaseMutationOptions<
  RefreshMutation,
  RefreshMutationVariables
>;
export const MeDocument = gql`
  query me {
    me {
      uuid
      username
      first_name
      last_name
      role
      email
      phone
      controlOrganizerId
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(
    MeDocument,
    options,
  );
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const OrderDocument = gql`
  query order($uuid: UUID!) {
    orderByUuid(uuid: $uuid) {
      createdAt
      id
      uuid
      status
      fixedHoldPercentage
      user {
        username
        first_name
        last_name
        gender
        email
        phone
      }
      pairOrder {
        user {
          uuid
          first_name
          last_name
          username
          gender
        }
        payment {
          price
        }
      }
      payment {
        price
      }
      product {
        uuid
        name
      }
      tariff {
        name
        price
      }
    }
  }
`;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useOrderQuery(
  baseOptions: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables> &
    ({ variables: OrderQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrderQuery, OrderQueryVariables>(
    OrderDocument,
    options,
  );
}
export function useOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(
    OrderDocument,
    options,
  );
}
export function useOrderSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OrderQuery,
    OrderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<OrderQuery, OrderQueryVariables>(
    OrderDocument,
    options,
  );
}
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderSuspenseQueryHookResult = ReturnType<
  typeof useOrderSuspenseQuery
>;
export type OrderQueryResult = Apollo.QueryResult<
  OrderQuery,
  OrderQueryVariables
>;
export const UserOrdersDocument = gql`
  query userOrders($filter: OrdersListFilterInput, $args: OrdersFilterInput) {
    ordersList(
      filters: $filter
      params: { status: [Paid, CompletedAndNotVisited, CompletedAndVisited] }
      args: $args
    ) {
      rows {
        id
        uuid
        status
        product {
          name
          startedDate
        }
        tariff {
          name
        }
      }
      count
    }
  }
`;

/**
 * __useUserOrdersQuery__
 *
 * To run a query within a React component, call `useUserOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOrdersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      args: // value for 'args'
 *   },
 * });
 */
export function useUserOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserOrdersQuery,
    UserOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<UserOrdersQuery, UserOrdersQueryVariables>(
    UserOrdersDocument,
    options,
  );
}
export function useUserOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserOrdersQuery,
    UserOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<UserOrdersQuery, UserOrdersQueryVariables>(
    UserOrdersDocument,
    options,
  );
}
export function useUserOrdersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UserOrdersQuery,
    UserOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<UserOrdersQuery, UserOrdersQueryVariables>(
    UserOrdersDocument,
    options,
  );
}
export type UserOrdersQueryHookResult = ReturnType<typeof useUserOrdersQuery>;
export type UserOrdersLazyQueryHookResult = ReturnType<
  typeof useUserOrdersLazyQuery
>;
export type UserOrdersSuspenseQueryHookResult = ReturnType<
  typeof useUserOrdersSuspenseQuery
>;
export type UserOrdersQueryResult = Apollo.QueryResult<
  UserOrdersQuery,
  UserOrdersQueryVariables
>;
export const ActivateOrderDocument = gql`
  mutation activateOrder($uuid: UUID!) {
    activateOrder(uuid: $uuid)
  }
`;
export type ActivateOrderMutationFn = Apollo.MutationFunction<
  ActivateOrderMutation,
  ActivateOrderMutationVariables
>;

/**
 * __useActivateOrderMutation__
 *
 * To run a mutation, you first call `useActivateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateOrderMutation, { data, loading, error }] = useActivateOrderMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useActivateOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateOrderMutation,
    ActivateOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    ActivateOrderMutation,
    ActivateOrderMutationVariables
  >(ActivateOrderDocument, options);
}
export type ActivateOrderMutationHookResult = ReturnType<
  typeof useActivateOrderMutation
>;
export type ActivateOrderMutationResult =
  Apollo.MutationResult<ActivateOrderMutation>;
export type ActivateOrderMutationOptions = Apollo.BaseMutationOptions<
  ActivateOrderMutation,
  ActivateOrderMutationVariables
>;
export const DeactivateOrderDocument = gql`
  mutation deactivateOrder($uuid: UUID!) {
    deactivateOrder(uuid: $uuid)
  }
`;
export type DeactivateOrderMutationFn = Apollo.MutationFunction<
  DeactivateOrderMutation,
  DeactivateOrderMutationVariables
>;

/**
 * __useDeactivateOrderMutation__
 *
 * To run a mutation, you first call `useDeactivateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateOrderMutation, { data, loading, error }] = useDeactivateOrderMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeactivateOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeactivateOrderMutation,
    DeactivateOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    DeactivateOrderMutation,
    DeactivateOrderMutationVariables
  >(DeactivateOrderDocument, options);
}
export type DeactivateOrderMutationHookResult = ReturnType<
  typeof useDeactivateOrderMutation
>;
export type DeactivateOrderMutationResult =
  Apollo.MutationResult<DeactivateOrderMutation>;
export type DeactivateOrderMutationOptions = Apollo.BaseMutationOptions<
  DeactivateOrderMutation,
  DeactivateOrderMutationVariables
>;
export const EventsDocument = gql`
  query events(
    $filters: ProductListFilterInput
    $organizerId: Int!
    $status: [StatusEnum!]
  ) {
    productsList(
      filters: $filters
      params: { organizerId: $organizerId }
      status: $status
    ) {
      ...EventsFields
    }
  }
  ${EventsFieldsFragmentDoc}
`;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      organizerId: // value for 'organizerId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useEventsQuery(
  baseOptions: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables> &
    ({ variables: EventsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<EventsQuery, EventsQueryVariables>(
    EventsDocument,
    options,
  );
}
export function useEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(
    EventsDocument,
    options,
  );
}
export function useEventsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EventsQuery,
    EventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<EventsQuery, EventsQueryVariables>(
    EventsDocument,
    options,
  );
}
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsSuspenseQueryHookResult = ReturnType<
  typeof useEventsSuspenseQuery
>;
export type EventsQueryResult = Apollo.QueryResult<
  EventsQuery,
  EventsQueryVariables
>;
export const EventDocument = gql`
  query event($uuid: UUID!) {
    product(uuid: $uuid) {
      uuid
      name
      description
      startedDate
      purchasedCount
      visitedCount
    }
  }
`;

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useEventQuery(
  baseOptions: Apollo.QueryHookOptions<EventQuery, EventQueryVariables> &
    ({ variables: EventQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<EventQuery, EventQueryVariables>(
    EventDocument,
    options,
  );
}
export function useEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EventQuery, EventQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<EventQuery, EventQueryVariables>(
    EventDocument,
    options,
  );
}
export function useEventSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EventQuery,
    EventQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<EventQuery, EventQueryVariables>(
    EventDocument,
    options,
  );
}
export type EventQueryHookResult = ReturnType<typeof useEventQuery>;
export type EventLazyQueryHookResult = ReturnType<typeof useEventLazyQuery>;
export type EventSuspenseQueryHookResult = ReturnType<
  typeof useEventSuspenseQuery
>;
export type EventQueryResult = Apollo.QueryResult<
  EventQuery,
  EventQueryVariables
>;
export const UsersDocument = gql`
  query users($filters: UserListFilterInput, $uuidProduct: UUID!) {
    usersByProduct(filters: $filters, uuidProduct: $uuidProduct) {
      rows {
        uuid
        createdAt
        first_name
        last_name
        username
        email
        phone
        gender
        ordersCount
        activatedOrdersCount
      }
      count
    }
  }
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      uuidProduct: // value for 'uuidProduct'
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables> &
    ({ variables: UsersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    options,
  );
}
export function useUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    options,
  );
}
export function useUsersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UsersQuery,
    UsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    options,
  );
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersSuspenseQueryHookResult = ReturnType<
  typeof useUsersSuspenseQuery
>;
export type UsersQueryResult = Apollo.QueryResult<
  UsersQuery,
  UsersQueryVariables
>;
export const UserDocument = gql`
  query user($uuid: UUID!) {
    user(uuid: $uuid) {
      uuid
      createdAt
      first_name
      last_name
      username
      email
      phone
      gender
      ordersCount
    }
  }
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables> &
    ({ variables: UserQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options,
  );
}
export function useUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options,
  );
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserSuspenseQueryHookResult = ReturnType<
  typeof useUserSuspenseQuery
>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const namedOperations = {
  Fragment: {
    EventsFields: 'EventsFields',
    JwtFields: 'JwtFields',
  },
  Mutation: {
    activateOrder: 'activateOrder',
    deactivateOrder: 'deactivateOrder',
    login: 'login',
    loginTg: 'loginTg',
    refresh: 'refresh',
  },
  Query: {
    event: 'event',
    events: 'events',
    me: 'me',
    order: 'order',
    user: 'user',
    userOrders: 'userOrders',
    users: 'users',
  },
};

export interface PossibleTypesResultData {
  possibleTypes: Record<string, string[]>;
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    BaseGraphModel: [
      'BaseTariffType',
      'BotType',
      'OrderInviteType',
      'OrderType',
      'OrganizerType',
      'PaymentAccountType',
      'PaymentType',
      'ProductType',
      'ReferralOrganizerType',
      'SubTariffType',
      'TariffType',
      'UserBalanceType',
      'UserBalanceWithdrawalType',
      'UserGroupType',
      'UserType',
      'WithdrawalType',
    ],
    NodeIdType: ['OrderType', 'OrganizerType'],
    NodeUuidType: [
      'BaseTariffType',
      'OrderInviteType',
      'PaymentAccountType',
      'PaymentType',
      'ProductType',
      'SubTariffType',
      'TariffType',
      'UserBalanceType',
      'UserBalanceWithdrawalType',
      'UserGroupType',
      'UserType',
      'WithdrawalType',
    ],
  },
};

export default result;
