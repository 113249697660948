import { useEffect, useState } from 'react';

import { useLoginTgMutation, useMeLazyQuery } from '@/apolloGenerated';
import {
  isDev,
  isOrganizerRole,
  isUserRole,
  persistUser,
  useTelegram,
} from '@/shared';
import app_config from '@app/constants';

export const useTelegramAuth = () => {
  const [isError, setIsError] = useState<boolean>(false);
  const [isPermissionDenied, setIsPermissionDenied] = useState<boolean>(false);
  const [
    signIn,
    { data: dataSignIn, error: errorSignIn, loading: loadingSignIn },
  ] = useLoginTgMutation();
  const [getMe, { data: dataMe, error: errorMe, loading: loadingMe }] =
    useMeLazyQuery();
  const { initDataUnsafe } = useTelegram();

  useEffect(() => {
    if (!initDataUnsafe.user?.id && !isDev) {
      return setIsError(true);
    }
    signIn({
      variables: {
        password: String(app_config.DEV_USER_ID || initDataUnsafe.user!.id),
        tgId: +app_config.DEV_USER_ID || initDataUnsafe.user!.id,
      },
    });
  }, []);

  useEffect(() => {
    if (dataSignIn) {
      persistUser(dataSignIn.loginTg);
      getMe();
    }
    if (errorSignIn) {
      setIsError(true);
    }
  }, [errorSignIn, dataSignIn]);

  useEffect(() => {
    if (dataMe) {
      const isUser = isUserRole(dataMe.me.role);
      const isInvalidOrg =
        isOrganizerRole(dataMe.me.role) && !dataMe.me.controlOrganizerId;

      if (isUser || isInvalidOrg) {
        setIsPermissionDenied(true);
      }
    }
    if (errorMe) {
      setIsError(true);
    }
  }, [errorMe, dataMe]);

  return {
    data: dataMe?.me,
    isError,
    isPermissionDenied,
    loading: loadingSignIn || loadingMe,
  };
};
