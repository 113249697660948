import { FC } from 'react';
import { useNavigate } from 'react-justanother-router';

import { LogoIcon } from '@/shared';
import { RouterName } from '@app/router';
import { Paper, Spacer, Typography } from '@letsdance/ui-kit';
import { AuthLogin } from '@widgets/Auth';

import styles from './styles.module.scss';

export const LoginView: FC = () => {
  const { navigate } = useNavigate();

  return (
    <>
      <LogoIcon width={180} height={90} className={styles.auth__logo} />
      <Spacer size={8} />
      <Paper className={styles.auth__content}>
        <Typography
          className={styles.auth__title}
          variant="head-24"
          color="on-surface-primary-1">
          Авторизация
        </Typography>
        <Spacer size={8} />
        <AuthLogin onSuccess={() => navigate(RouterName.QrScan)} />
      </Paper>
    </>
  );
};
