import { FC, PropsWithChildren } from 'react';
import { Redirect } from 'react-justanother-router';

import { MeQuery } from '@/apolloGenerated';
import { isDev, useTelegram, useTelegramAuth, useTelegramMeta } from '@/shared';
import { RouterName } from '@app/router';
import { TgPermissionError, TgRequirementsMessage } from '@entities/Telegram';
import { LoaderOverlay } from '@letsdance/ui-kit';

export interface BaseLayoutProps {
  user?: MeQuery['me'];
}
export const BaseLayout: FC<PropsWithChildren<BaseLayoutProps>> = ({
  children,
  user,
}) => {
  const { isVersionAtLeast, platform } = useTelegram();
  const { isError, isPermissionDenied, loading } = useTelegramAuth();
  const isTelegramApiUpdated = isVersionAtLeast('6.9');
  const isTelegramClient = platform !== 'unknown';

  useTelegramMeta();

  if ((!isTelegramClient || !isTelegramApiUpdated) && !isDev) {
    return (
      <TgRequirementsMessage
        isTelegramApiUpdated={isTelegramApiUpdated}
        isTelegramClient={isTelegramClient}
      />
    );
  }

  if (user) {
    return children;
  }

  if (isPermissionDenied) {
    return <TgPermissionError />;
  }
  if (isError) {
    return <Redirect to={RouterName.Login} />;
  }

  return (
    <>
      <LoaderOverlay show={loading} />
      {children}
    </>
  );
};
