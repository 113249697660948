import { FC, PropsWithChildren } from 'react';
import cx from 'classnames';

import { useMeQuery } from '@/apolloGenerated';
import { Container } from '@/shared';
import { BaseLayout } from '@widgets/Layout';

import { Header } from '../Header/Header';
import { Navigation } from '../Navigation/Navigation';

import styles from './styles.module.scss';

export interface UserLayoutProps {
  title?: string;
  slotTop?: FC;
  hideNav?: boolean;
  hideHead?: boolean;
}
export const UserLayout: FC<PropsWithChildren<UserLayoutProps>> = ({
  children,
  hideHead,
  hideNav,
  slotTop: TopComponent,
  title,
}) => {
  const { data } = useMeQuery();

  return (
    <BaseLayout user={data?.me}>
      <div
        className={cx(
          styles.userLayout,
          hideNav && styles.userLayout_fullHeight,
        )}>
        <Header title={title} hideHead={hideHead} user={data?.me}>
          {TopComponent && <TopComponent />}
        </Header>
        <Container className={styles.userLayout__content}>{children}</Container>
      </div>
      {!hideNav && <Navigation />}
    </BaseLayout>
  );
};
